<template>
    <v-flex class="d-flex flex-column">
        <v-flex class="gst-search-result-tabs-all-section-header d-flex align-center justify-space-between pt-4 pb-2">
            <h4>
                {{ title }}
            </h4>
            <router-link
                v-if="showButton"
                class="d-flex align-center gst-btn--see-all"
                :to="to">
                <span>{{ textButton }}</span>
                <i class="gst-icon-svg u-rotate-90-n">
                    <BaseIcon symbol-id="icons--dropdown_arrow" />
                </i>
            </router-link>
        </v-flex>
    </v-flex>
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'SearchResultTabsAllSectionHeader',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.search.theSearch._components.searchResultTabs'
        },
        components: {
            BaseIcon
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            to: {
                type: Object,
                default: () => ( {} )
            },
            textButton: {
                type: String,
                default: ''
            },
            showButton: {
                type: Boolean,
                default: true
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-search-result-tabs-all-section-header {
        h4 {
            color: theme-color( 'new-quaternary' );
            font-weight: font-weight( 'large' );
        }

        .gst-btn--see-all {
            color: theme-color( 'new-primary' ) !important;
            font-weight: font-weight( 'large' );

            .gst-icon-svg {
                ::v-deep .gst-svg-icon {
                    fill: theme-color( 'new-primary' ) !important;
                }
            }
        }
    }
</style>
